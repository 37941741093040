import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";

const formatFromISO = (date: string | undefined, formatString: string) => {
  if (!date) return "";
  return format(parseISO(date), formatString, { locale: fr });
};

/**
 * This function will return the date formatted as follow :
 * If the date is : 09/11/2022 (which is a mercredi) -> Mercredi 09/11/2022
 * @param date to format with the day of the week before.
 * @returns a formatted string of the date.
 */
const formatDateWithDayStr = (date: Date) => {
  return date.toLocaleString("default", { weekday: "long", day: "2-digit", month: "2-digit", year: "numeric" });
};

/**
 * This function will return the date formatted as follow :
 * If the date is : 01/05/2022 -> 01 mai 2022
 * @param date to format with the month of the year in str
 * @returns a formatted string of the date
 */
const formatDateWithMonthStr = (date: Date) => {
  return date.toLocaleString("fr", { day: "2-digit", month: "long", year: "numeric" });
};

/**
 * This function will return the date formatted as follow :
 * 01/05/2022
 * @param date to format with the month of the year in the Belgian format
 * @returns a formatted string of the date
 */
const formatDateBelgian = (date: Date) => {
  return date.toLocaleString("fr-BE", { day: "2-digit", month: "2-digit", year: "numeric" });
};

export { formatFromISO, formatDateWithDayStr, formatDateWithMonthStr, formatDateBelgian };
