import { z } from "zod";

import { FILE_ORIGIN, FILE_STATUS } from "~/constants/file";

export const fileSchema = z.object({
  id: z.string().uuid(),
  origin: z.union([z.string(), z.nativeEnum(FILE_ORIGIN)]), // If data comes from DB, it is a string
  status: z.union([z.string(), z.nativeEnum(FILE_STATUS)]), // If data comes from DB, it is a string
  name: z.string(),
  uri: z.string(),
  size: z.number(),
  mime_type: z.string(),
  signing_report: z.string().nullable().optional(),
});
// .passthrough();
