import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { LinkProps } from "next/link";
import Link from "next/link";
import type { PropsWithRef } from "react";
import { forwardRef } from "react";
import type { HeroIcon } from "~/utils/types";
type Size = "sm" | "base" | "lg";
type Variant = "inline" | "underline" | "plain" | "outline" | "ghost";
type Flavor = "primary" | "neutral" | "var";
const getButtonClassName = ({
  variant,
  size,
  flavor,
  withUnderlineStarted,
  borderInLeftOutRight,
  disabled,
  unstyled
}: {
  variant: Variant;
  size: Size;
  flavor: Flavor;
  withUnderlineStarted: boolean;
  borderInLeftOutRight: boolean;
  disabled: boolean;
  unstyled: boolean;
}) => {
  const inlineVariant = clsx("duration-150 ease-in-out font-semibold", {
    "text-neutral-500": disabled,
    "text-action hover:text-cherry-red": flavor === "primary" && !disabled,
    //TODO check if it's the good color for this !
    "text-neutral-600 hover:text-neutral-800": flavor === "neutral" && !disabled
  });
  const underlineVariant = clsx("after:block after:border-b-3 after:border-current hover:after:border-current after:transition-transform after:content-[''] focus:outline-none font-semibold", {
    "text-neutral-500": disabled,
    "text-cherry-red hover:text-cherry-red duration-200 hover:after:scale-x-100": !disabled
  }, {
    "after:origin-right hover:after:origin-left": borderInLeftOutRight,
    "after:origin-left": !borderInLeftOutRight,
    "after:scale-x-[0.15]": withUnderlineStarted,
    "after:scale-x-[0]": !withUnderlineStarted
  });
  const plainVariant = clsx("duration-150 ease-in-out font-extralight focus:outline-none rounded-[3px]", {
    "py-2 px-4": size === "sm",
    "px-3 py-2": size === "base",
    "py-3 px-6": size === "lg"
  }, {
    "text-white bg-neutral-700 cursor-default": disabled,
    "text-white bg-action hover:bg-cherry-red": flavor === "primary" && !disabled,
    "text-neutral-500 bg-white hover:text-neutral-800 shadow-button hover:shadow-button-hover": flavor === "neutral" && !disabled
  });
  const outlineVariant = clsx("duration-200 ease-in-out border-[1.5px] flex items-center border-dashed font-medium italic rounded-[3px] font-semibold", {
    "p-2": size === "sm",
    "py-1.5 px-3": size === "base",
    "py-2 px-4": size === "lg"
  }, {
    "text-neutral-500 cursor-default": disabled,
    "text-neutral-700 border-neutral-700 hover:text-neutral-800 hover:border-solid hover:border-neutral-800": !disabled
  });
  const ghostVariant = clsx("duration-200 ease-in-out flex items-center font-medium", {
    "p-2": size === "sm",
    "py-1.5 px-3": size === "base",
    "py-2 px-4": size === "lg"
  }, {
    "text-neutral-500 cursor-default": disabled,
    "text-cherry-red border-cherry-red hover:bg-cherry-red hover:text-white": flavor === "primary" && !disabled,
    "text-black": flavor === "neutral" && !disabled,
    "h-[38px] w-fit font-poppins text-neutral-700 italic rounded-[3px] hover:bg-grey-blue-60": flavor === "var" && !disabled
  });
  return clsx("inline-block h-10 outline-none", !unstyled && {
    [underlineVariant]: variant === "underline",
    [plainVariant]: variant === "plain",
    [inlineVariant]: variant === "inline",
    [outlineVariant]: variant === "outline",
    [ghostVariant]: variant === "ghost"
  }, {
    "text-xs": size === "sm",
    "text-sm": size === "base",
    "text-lg": size === "lg"
  });
};

/**  --------------
 *
 * Types safety for the additional props combinations
 *
 *  -------------- */

// TODO: merge these types with those on the top.

type AdditionalProps = ({
  variant?: "underline";
  withUnderlineStarted?: boolean;
  borderInLeftOutRight?: boolean;
  flavor?: never;
} | {
  variant?: "plain";
  withUnderlineStarted?: never;
  borderInLeftOutRight?: never;
  flavor?: Flavor;
} | {
  variant?: "inline";
  withUnderlineStarted?: never;
  borderInLeftOutRight?: never;
  flavor?: Flavor;
} | {
  variant?: "outline";
  withUnderlineStarted?: never;
  borderInLeftOutRight?: never;
  flavor?: Flavor;
} | {
  variant?: "ghost";
  withUnderlineStarted?: never;
  borderInLeftOutRight?: never;
  flavor?: Flavor;
}) & {
  size?: Size;
  unstyled?: boolean;
};

/**  --------------
 *
 * Type guard for the polymorphic button
 *
 *  -------------- */

// Anchor and Link props
export type HTMLAnchorLinkProps = Omit<React.ComponentPropsWithoutRef<"a">, "href"> & Pick<LinkProps, "href" | "as" | "replace" | "scroll" | "shallow" | "prefetch" | "locale"> & AdditionalProps & {
  disabled?: undefined;
}; // Disabled is needed to pass the props correctly in the button/link component but proves the fact you cannot use disabled on a Link.

export type HTMLButtonProps = React.ComponentPropsWithoutRef<"button"> & AdditionalProps;
type ButtonOrAnchorLinkProps = HTMLButtonProps | HTMLAnchorLinkProps;

// Input/output options
type ButtonProps = {
  (props: PropsWithRef<HTMLButtonProps>): JSX.Element;
  (props: PropsWithRef<HTMLAnchorLinkProps>): JSX.Element;
};

// Guard to check if href exists in props
const hasHref = (props: ButtonOrAnchorLinkProps): props is HTMLAnchorLinkProps => "href" in props;
export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonOrAnchorLinkProps>(function Button({
  variant = "inline",
  withUnderlineStarted = false,
  borderInLeftOutRight = false,
  size = "base",
  flavor = "primary",
  disabled = false,
  unstyled = false,
  className,
  ...props
}, ref) {
  const formattedClassName = getButtonClassName({
    flavor,
    size,
    variant,
    withUnderlineStarted,
    borderInLeftOutRight,
    disabled,
    unstyled
  });
  if (hasHref(props)) {
    return <Link ref={ref as React.ForwardedRef<HTMLAnchorElement>} className={clsx(formattedClassName, className)} {...props} />;
  }
  return <button type={props.type as HTMLButtonProps["type"] ?? "button"} // Avoid default submit on click in forms
  className={clsx(formattedClassName, className)} ref={ref as React.ForwardedRef<HTMLButtonElement>} disabled={disabled} {...props as HTMLButtonProps} />;
}) as ButtonProps;
export const ChapterNavButton = forwardRef(function ChapterNavButton(props: Omit<React.ComponentProps<"button">, "className">, forwardedRef: React.ForwardedRef<HTMLButtonElement>) {
  return <Button ref={forwardedRef} unstyled className="flex items-center gap-4 rounded-3xl border border-black px-7 py-2.5 disabled:border-neutral-300 disabled:text-neutral-300" {...props} />;
});
export const ActionButton = forwardRef<HTMLButtonElement, React.ComponentProps<"button"> & {
  Icon: HeroIcon;
  isModalOpen?: boolean;
  small?: boolean;
  action?: string;
}>(function ActionButton({
  Icon,
  isModalOpen,
  className,
  action,
  small = false,
  ...props
}, ref) {
  return <button {...props} ref={ref} className={clsx("text-black hover:bg-aubergine-20 active:text-aubergine active:bg-aubergine-40 disabled:hover:bg-none rounded-md py-1 px-1.5 transition ease-in-out disabled:opacity-50 disabled:cursor-default", className, isModalOpen && "bg-neutral-500")} type={props.type as HTMLButtonProps["type"] ?? "button"} // Avoid default submit on click in forms
  {...props}>
      {!!action ? <span className="sr-only">{action}</span> : null}
      <Icon className={clsx(small ? "h-5 w-5" : "h-6 w-6")} />
    </button>;
});
export const ActionLink = forwardRef<HTMLAnchorElement, LinkProps & {
  Icon: HeroIcon;
  small?: boolean;
  action?: string;
}>(function ActionLink({
  Icon,
  action,
  small = false,
  ...props
}, ref) {
  return <Link ref={ref} {...props} passHref className="text-black hover:bg-aubergine-20 active:text-aubergine active:bg-aubergine-40 disabled:hover:bg-transparent rounded-md py-1 px-1.5 transition ease-in-out disabled:opacity-50 disabled:cursor-default">
      {action ? <span className="sr-only">{action}</span> : null}
      <Icon className={clsx(small ? "h5 w-5" : "h-6 w-6")} />
    </Link>;
});
export const BackButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, React.ComponentProps<"button">>(function BackButton({
  className,
  ...props
}, ref) {
  return <Button ref={ref} flavor="neutral" variant="inline" className={clsx("h-fit w-fit py-1 px-1 group flex items-center justify-center", className)} {...props}>
      <div className="border-[1.5px] py-0.5 px-0.5 border-neutral-800 text-neutral-800 group-hover:border-aubergine-60 group-hover:text-aubergine-60 duration-300 ease-in-out rounded-full">
        <ChevronLeftIcon className="h-[22px] w-[22px] stroke-[2px] -translate-x-[1px]" />
      </div>
    </Button>;
}) as ButtonProps;